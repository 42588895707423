









import Vue from 'vue'

import { Accessors } from 'vue/types/options'
import { mapGetters } from 'vuex'
import { toFormData } from 'vuelpers'
import { Report, User } from '@/types'
import { ReportsApi } from '@/api/auth.api'

import FormReportProblem from '@/components/Forms/FormReportProblem.vue'

export default Vue.extend({
	name: 'ReportProblem',
	components: {
		FormReportProblem,
	},
	computed: {
		...(mapGetters('auth', ['$currentUser']) as Accessors<{
			$currentUser: User
		}>),
	},
	methods: {
		onSubmitReport(report: Partial<Report>) {
			const { reportableId, reportableType } = this.$route.query
			return ReportsApi.post(
				toFormData({
					...report,
					reportable_id: reportableId || this.$currentUser?.iUserId,
					reportable_type:
						reportableType ??
						(this.$currentUser ? 'App\\Models\\User' : 'Anonymous'),
				})
			)
		},
	},
})
