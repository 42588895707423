























































































































































































import Vue from 'vue'
import { getValidators } from '@/mixins/FormMixin'
import {
	Attachment,
	BackendError,
	Id,
	Product,
	Report,
	User,
	VFormRef,
} from '@/types'
import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { VDialog } from 'vuetify/lib/components'
import { ProductsApi } from '@/api'
import { productWithForReportAProblem } from '@/queries'
import ProductPreviewCard from '../ProductPreviewCard.vue'

type IReport = {
	type: string
	tDescription: string
	vImage: Partial<Attachment>[]
	vContactName: string
	vContactEmail: string
	vContactPhone: string
}

export default Vue.extend({
	name: 'FormReportProblem',
	components: {
		VDialog,
		ProductPreviewCard,
	},
	props: {
		dialog: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		onSubmit: {
			required: true,
			type: Function as Vue.PropType<(report: any) => Promise<any[]>>,
		},
	},
	data: () => ({
		isBot: '',
		isValid: false,
		isLoading: false,
		attachmentError: false,
		rules: getValidators('required', 'email'),
		errors: {} as BackendError,
		mReport: {} as IReport,
		dialogModel: false,
		product: null as Product | null,
	}),
	created() {
		this.mReport = this.initialReport()
	},
	computed: {
		...(mapGetters('auth', ['$currentUser']) as Accessors<{
			$currentUser: User
		}>),
		iProductId(): Id | null {
			const { reportableId, reportableType } = this.$route.query
			if (reportableType.toString().toLowerCase().includes('product')) {
				return +reportableId as Id
			}
			return null
		},
	},
	watch: {
		iProductId: {
			immediate: true,
			async handler(v: Id) {
				if (!v) return
				const [err, res] = await ProductsApi.show(v, {
					with: productWithForReportAProblem,
				})
				if (err) {
					return this.$toast.error(
						err.message || 'Error while fetching product informations.'
					)
				}
				this.product = res.product
			},
		},
		$currentUser: {
			deep: true,
			handler() {
				this.mReport = this.initialReport()
			},
		},
	},
	methods: {
		initialReport(v?: Report): IReport {
			console.log('initialReport', this.$currentUser)
			return {
				type: 'error',
				tDescription: v?.tDescription || '',
				vContactName: this.$helper.fullName(this.$currentUser) || '',
				vContactEmail: this.$currentUser?.email || '',
				vContactPhone: this.$currentUser?.vMobileNumber || '',
				vImage: (v?.vImage || '')
					.split(',')
					.filter((v) => v)
					.map((v) => ({
						src: this.$helper.imgSrc(v, {
							size: 'none',
							type: 'reviews',
						}),
					})),
			}
		},
		async onSubmitReport() {
			const formRef = this.$refs.formRef as VFormRef
			if (!formRef.validate()) return

			if (this.isBot) {
				formRef.reset()
				return this.$toast.error(
					"Great! You have proven to be a bot! I've destroyed the form the page, and acted like you successfully sent the form."
				)
			}

			this.isLoading = true
			const [err] = await this.onSubmit({
				...this.mReport,
				vImage: this.mReport.vImage
					.filter((img) => img.file)
					.map((img) => img.file),
			})
			this.isLoading = false

			if (!err) {
				formRef.reset()
				this.mReport = this.initialReport()
				this.$toast.success('Report submitted successfully! Thank you.')

				if (this.dialog) this.dialogModel = false
				return this.$helper.goBack('/products')
			}
			this.$toast.error('Error while submitting report! Please try again.')
		},
	},
})
