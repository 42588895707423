














































import Vue from 'vue'
export default Vue.extend({
	name: 'ProductPreviewCard',
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		thumbnail(): string {
			const vImage = this.product.vImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
	},
})
